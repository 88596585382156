import React from "react"
import styled from 'styled-components'

import Logo from '../assets/high-def-logo.svg';


const StyledHeader = styled.header`
  width: 100vw;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  box-sizing: border-box;
`

const StyledLogo = styled(Logo)`
  width: 100vw;
  height: 90px;
`

const Header = () => (
  <StyledHeader>
    <StyledLogo />
   
  </StyledHeader>
)

export default Header
